<template>
  <div>
    <b-card>
        <KTRiskParameterInformation
          :record="editRiskParameterRecord"
        />
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import KTRiskParameterInformation from "@/view/pages/management/riskparameters/RiskparametersInformation";

export default {
  name: "RiskparametersInformation",
  components: {
    KTRiskParameterInformation,
  },
  data() {
    return {
      editRiskParameterRecord: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Risk Parameters" }]);
    this.editRiskParameterRecord = this.currentUser.riskreview
  },
  methods: {
    finished() {
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
