<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"          
        >
          Edit Risk Parameters
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" v-if="record.low_color">
      <!--begin::Body-->

      <div class="card-body">
          <h3>Low Risk</h3>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Max Score</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="low_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.low_score"
              />
            </div>
          </div>
  
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Review every</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="low_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.low_review"
              />
            </div>
          </div>
  
      </div>


      <div class="card-body">
          <h3>Medium Risk</h3>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Max Score</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="med_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.med_score"
              />
            </div>
          </div>
  
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Review every</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="med_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.med_review"
              />
            </div>
          </div>
  
      </div>


      <div class="card-body">
          <h3>High Risk</h3>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Max Score</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="high_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.high_score"
              />
            </div>
          </div>
  
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Review every</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="high_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.high_review"
              />
            </div>
          </div>
  
      </div>


      <div class="card-body">
          <h3>Very High Risk</h3>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Max Score</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="vhigh_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.vhigh_score"
              />
            </div>
          </div>
  
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Review every</label>
            <div class="col-lg-9 col-xl-6">
              <input
                ref="vhigh_score"
                class="form-control form-control-lg form-control-solid"
                type="number"
                v-model.trim="record.vhigh_review"
              />
            </div>
          </div>
  
      </div>

    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_REVIEW_DETAILS,
} from "@/core/services/store/users.module";

export default {
  name: "PersonalInformation",
  props: {
    record: Object,
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  methods: {
    save() {

        this.$store
          .dispatch(UPDATE_REVIEW_DETAILS, this.record)
          .then(() => {
              //window.location.reload();
            });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
